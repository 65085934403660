import React, { Component } from 'react';
import { connect } from 'react-redux'
import './style.css';
import PaymentForm from './components/PaymentForm.js';
import { SQUARE_SCRIPT_URL } from '../../assets/Constants';
import NewNavBar from '../../components/UI/NewNavBar/index';
import { store, getFromLocalStorage, deleteFromLocalStorage } from '../../store/index'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Modal from 'react-bootstrap/Modal';
import Toast from 'react-bootstrap/Toast';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import LazyLoad from 'react-lazy-load';
import Img from 'react-image'
import CustomButton from '../../components/UI/Button/index';
import { theme } from '../../components/UI/theme';
import PlacesAutocomplete from 'react-places-autocomplete';
import 'date-fns';
import moment from 'moment';
import { applyToken } from '../../services/api'
import {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from 'react-places-autocomplete';

import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

import {
  CartComponent,
  ProductComponent,
  CheckoutButtonComponent,
  cartLocalization
} from "react-shopping-cart";
import {
  updateOrderType, updateOrderLater, updateOrderLocation,
  changeTab, calculateDelivery, getDelivery, updateDeliveryCharges,
  orderStatus, getPromoCode, updateCartTotal, updateCartProducts, applyPromoCode, updateOrderDate, validatePromoCode,
} from '../../actions';
import ExploreComponent from '../../components/ExploreComponent';
import MapComponent from '../../components/MapComponent';
import VendorComponent from '../../components/VendorComponent';
import FloatingPanel from '../../components/UI/FloatingPanel';
import { isRestaurantOpen } from '../../assets/Utils';
import config from '../../services/apiConfig';
import { TramRounded } from '@material-ui/icons';
import { apiPaths } from '../../services/apiPath';
const { getDefaultLocalization } = cartLocalization;


const iPadCaseLocalization = {
  color: "Color",
  iPadCase: "iPad case",
  red: "Red",
  green: "Green",
  yellow: "Yellow",
  AUD: "$"
};

const iPadPropertiesWithAdditionalCostLocalization = {
  yellow: "Yellow"
};


const localization = {
  AUD: '$',
};


class CheckoutResponsiveScreen extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loaded: false,
      orderStatusState: '',
      orderTypeState: '',
      orderLocationState: '',
      orderLaterState: false,
      orderDateState: '',
      productsState: {},
      productsTotalState: 0,
      deliveryChargesState: 0,
      paymentStatusState: '',
      paymentSourceState: '',
      paymentReceiptNumberState: '',
      paymentErrorState: '',
      paymentErrorDetailsState: '',
      paymentWttChargesState: 0.0,
      paymentTxnChargesState: 0.0,
      paymentTotalChargesState: 0.0,
      paymentProcessStarted: false,
      serverDeliveryChargesArray: new Array(),
      orderPromoCodeValue: "",
      orderPromoCodeState: new Array(),
      promoCodeError: '',
      applyPromoCodeState: '',
      discountState: 0.0,
      getCheckoutButtonLocalization: getDefaultLocalization(
        "checkoutButton",
        "en",
        iPadCaseLocalization
      ),

      getCartLocalization: getDefaultLocalization(
        "cart",
        "en",

      ),
      selectedEvent: null,
      isPaymentModalOpen: false,
      isOpenLoginModal: false,
      showRequiredToast: false,
      showToastMessage: '',
      showRemoveItemConfirmation: false,
      removeItemKey: '',
      isLogout: false,
      kitchacoLocations: [{
        "id": 1,
        "location": "31 Malua Street, Reservoir VIC 3073",
        "suburb": "Reservoir",
        "email": "kitchacoreservoir@gmail.com",
        "support_phone": "0421119859",
        "sales_email": null,
        "lat": "144.9820211",
        "lng": "-37.7187343",
        "monday_time": "0",
        "tuesday_time": "0",
        "wednesday_time": "0",
        "thursday_time": "0",
        "friday_time": "0",
        "saturday_time": "09:00-21:00",
        "sunday_time": "0",
        "driver_delivery_charges_per_km": "1.00",
        "driver_min_delivery_charges": "4.99",
        "square_location_id": "N1X5PR1BE0G7T",
        "square_terminal_device_code": "0",
        "square_terminal_device_id": "0",
        "square_terminal_device_code_id": "0",
        "kiosk_pay_via_cash": true,
        "kiosk_cash_ids": "3742",
        "kiosk_pay_via_cash_passcode": "7877",
        "is_kiosk_only": false,
        "is_voucher_enabled": false,
        "voucher_dollar_value": "0.00",
        "kiosk_ids": "3742",
        "show_top_seller": false,
        "number_of_top_sellers": "50",
        "is_event_location": false
      },
      {
        "id": 5,
        "location": "726 Sydney Rd, Brunswick VIC 3056",
        "suburb": "Brunswick",
        "email": "kitchaco_brunswick@gmail.com",
        "support_phone": "0393836900",
        "sales_email": null,
        "lat": "-37.7602917",
        "lng": "144.9612893",
        "monday_time": "11:00-23:00",
        "tuesday_time": "11:00-23:00",
        "wednesday_time": "11:00-23:00",
        "thursday_time": "11:00-23:00",
        "friday_time": "11:00-23:00",
        "saturday_time": "11:00-23:00",
        "sunday_time": "11:00-23:00",
        "driver_delivery_charges_per_km": "1.00",
        "driver_min_delivery_charges": "4.99",
        "square_location_id": "L8M61DD1WCB84",
        "square_terminal_device_code": "0",
        "square_terminal_device_id": "0",
        "square_terminal_device_code_id": "0",
        "kiosk_pay_via_cash": false,
        "kiosk_cash_ids": "",
        "kiosk_pay_via_cash_passcode": "2931",
        "is_kiosk_only": false,
        "is_voucher_enabled": false,
        "voucher_dollar_value": "0.00",
        "kiosk_ids": "4419",
        "show_top_seller": false,
        "number_of_top_sellers": "50",
        "is_event_location": false
      },
      {
        "id": 13,
        "location": "920 Yan Yean Rd Doreen VIC 3754",
        "suburb": "Doreen",
        "email": "kitchacodoreen@gmail.com",
        "support_phone": "0397171379",
        "sales_email": "info@kitchaco.com",
        "lat": "-37.6055504",
        "lng": "145.1380805",
        "monday_time": "0",
        "tuesday_time": "0",
        "wednesday_time": "0",
        "thursday_time": "0",
        "friday_time": "0",
        "saturday_time": "0",
        "sunday_time": "0",
        "driver_delivery_charges_per_km": "0.00",
        "driver_min_delivery_charges": "0.00",
        "square_location_id": "LSFZDFQFH2ZEW",
        "square_terminal_device_code": "VQNJXF,PDJRPN",
        "square_terminal_device_id": "952CS126A6000695,028CS126A6003337,952CS126A6000695,952CS126A6000695,952CS126A6000695",
        "square_terminal_device_code_id": "NTVW5G0YAX74F,QJ8FW3YBQ73DF",
        "kiosk_pay_via_cash": true,
        "kiosk_cash_ids": "0,5086",
        "kiosk_pay_via_cash_passcode": "16",
        "is_kiosk_only": true,
        "is_voucher_enabled": false,
        "voucher_dollar_value": "0.00",
        "kiosk_ids": "5160,5086",
        "show_top_seller": true,
        "number_of_top_sellers": "50",
        "is_event_location": false
      },
    
    ],
      isOpenPaymentConfirmation:false,
      futureDateError:false,
    }
  }

  componentWillMount() {

    let sqPaymentScript = document.createElement('script');
    // sandbox: https://js.squareupsandbox.com/v2/paymentform
    // production: https://js.squareup.com/v2/paymentform
    sqPaymentScript.src = SQUARE_SCRIPT_URL;
    sqPaymentScript.type = "text/javascript"
    sqPaymentScript.async = false;
    sqPaymentScript.onload = () => {
      this.setState({
        loaded: true
      })
    };
    document.getElementsByTagName("head")[0].appendChild(sqPaymentScript);
  }

  componentDidUpdate(prevProps, prevState) {

    const { appReducer } = store.getState()
    const { products } = appReducer
    const { dispatch } = this.props
    console.log("called componentDidUpdate")
    if (this.state.productsState !== products) {
      console.log("called equal this.state.productsState", this.state.productsState)
      console.log("called equal products", products)
      dispatch(updateCartProducts(this.state.productsState))
      dispatch(updateCartTotal(this.calculateCartTotal(this.state.productsState)))
      //setCartProducts(this.state.productsState)

    }
    else {
      console.log("called not equal this.state.productsState", this.state.productsState)
      console.log("called not equal products", products)
    }
  }

  componentDidMount() {
    const { appReducer } = store.getState()
    const {
      productsTotal,
      products,
      orderDate,
      orderType,
      orderLater,
      deliveryCharges,
      orderPromoCode,
      discount,
      applyPromoCode,
      userId
    } = appReducer

    const {
      productsState,
      productsTotalState,
      orderLaterState,
      orderTypeState,
      deliveryChargesState,
      orderPromoCodeState,
      applyPromoCodeState,
      discountState,
    } = this.state

    const {
      dispatch
    } = this.props

    if (this.isEmpty(productsState)) {
      this.setState({ productsState: products }, () => {
      })
    }


    if (productsTotalState == 0) {
      this.setState({ productsTotalState: productsTotal })
    }
    if (getFromLocalStorage("selectedEvent") != "undefined") {
      this.setState({ selectedEvent: JSON.parse(getFromLocalStorage("selectedEvent")) })
    }

    if (orderTypeState == '') {
      this.setState({ orderTypeState: orderType })
    }
    if (orderLaterState == '') {
      this.setState({ orderLaterState: orderLater })
    }
    if (orderDate == "" || orderDate == null) {
      this.setState({ orderDateState: moment(new Date()).format('YYYY-MM-DDTHH:mm') }, () => {
        dispatch(updateOrderDate(this.state.orderDateState))
      })
    }
    else {
      this.setState({ orderDateState: orderDate })
    }

    if (deliveryChargesState == '') {
      this.setState({ deliveryChargesState: deliveryCharges })
    }
    if (orderPromoCodeState == "") {
      this.setState({ orderPromoCodeState: orderPromoCode })
    }
    // if (getFromLocalStorage('orderStatus') === "Received" || getFromLocalStorage('orderStatus') === "Cooking" || getFromLocalStorage('orderStatus') === "Ready for pickup" || getFromLocalStorage('orderStatus') === "On its way" ) {
    //   this.checkOrderStatus.bind(this)
    //   setInterval(this.checkOrderStatus.bind(this), 30000);
    // }
    if (userId != "" && userId != null) {
      applyToken(getFromLocalStorage('token'))
      this.props.dispatch(getPromoCode()).then((data) => {
        console.log("getPromoCode", data)
        try {
          this.setState({ orderPromoCodeState: data['payload'] })
        }
        catch (err) {
          this.setState({ isLogout: true })
        }
      })
    }

    if (applyPromoCodeState == '') {
      this.setState({ applyPromoCodeState: applyPromoCode })
    }
    if (discountState == '') {
      this.setState({ discountState: discount })
    }

    this.getKitchacoLocationData()

  }


  calculateSubTotal = () => {
    const { productsTotalState, discountState } = this.state


    var subTotal = Number(productsTotalState)
    if (Number(subTotal) > 0) {
      return subTotal
    }
    else {
      console.log("subtotal ", subTotal)
      window.location.href = "/v2/kitchaco:" + 1
    }

  }

  calculateTotal = () => {

    const { productsTotalState, discountState, deliveryChargesState } = this.state
    var total = Number(productsTotalState) - Number(discountState) + Number(deliveryChargesState)
    return total
  }

  calculateCartTotal = (products) => {
    var price = 0
    for (var key in products) {
      price += Number(products[key].prices.AUD) * Number(products[key].quantity);
      products[key].additionalCost && products[key].additionalCost.map((item) => {
        var additionalPrice = Object.values(item)[0] * Number(products[key].quantity)
        if (!isNaN(Number(additionalPrice))) {
          price += Number(additionalPrice)
        }

      })
    }
    this.setState({ productsTotalState: price })
    return price;
  }


  togglePaymentModal = () => {
    const {
      isPaymentModalOpen,
      orderDateState,
      orderTypeState,
      orderLaterState,
      kitchacoLocations
    } = this.state

    const { appReducer } = store.getState()
    const selectedLocationId = 5


    var selectedLocationObj = kitchacoLocations.filter(obj => {
      return obj.id == selectedLocationId
    })


    if (orderTypeState == '' || orderTypeState == null) {

      document.getElementById('order-type').scrollIntoView()
      document.getElementById('order-type').style.border = "1px solid #dd3544";
      document.getElementById('order-type').style.padding = "5px";
      this.setState({ showRequiredToast: true, showToastMessage: 'Please select the order type' })

    }
    else if (orderDateState == '' || orderDateState == null) {
      document.getElementById('order-time').scrollIntoView()
      document.getElementById('order-time').style.border = "1px solid #dd3544";
      document.getElementById('order-time').style.padding = "5px";
      this.setState({ showRequiredToast: true, showToastMessage: 'Please select the order date' })
    }
    else if (!isRestaurantOpen(moment, kitchacoLocations, selectedLocationObj, 'schedule_order', orderDateState, orderDateState)) {
      this.setState({ showRequiredToast: true, showToastMessage: 'The restaurant is closed at the selected date time.' })
      document.getElementById('opening-hours').scrollIntoView()
      document.getElementById('opening-hours').style.border = "1px solid #dd3544";
      document.getElementById('opening-hours').style.padding = "5px";
    }

    else if (!this.validateOrderForLaterTime(orderDateState) && orderLaterState == true) {
      this.setState({ showRequiredToast: true, showToastMessage: 'Please select a time atleast 15 minutes in the future!', futureDateError: true, orderDateState: '' })
      document.getElementById('order-later-time').scrollIntoView()
      document.getElementById('order-later-time').style.border = "1px solid #dd3544";
      document.getElementById('order-later-time').style.padding = "5px";
    }
    else {
      this.state.isPaymentModalOpen
        ?
        this.setState({ isPaymentModalOpen: false })
        :
        this.setState({ isPaymentModalOpen: true })
    }

  }

  openPaymentConfirmation = () => {
    this.setState({ isPaymentModalOpen: false, isOpenPaymentConfirmation: true })
  }


  isEmpty = obj => {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  };

  changePromo() {
    const { dispatch } = this.props
    const { productsTotalState } = this.state;
    const { appReducer } = store.getState()
    this.setState({ orderPromoCodeValue: '' })
    var orderPromoCodeValue = ''
    var discount = 0.00
    this.removeProduct("freeitem")
    dispatch(updateCartProducts(this.state.productsState))
    var newProductsTotalState = this.calculateCartTotal(this.state.productsState)
    dispatch(updateCartTotal(newProductsTotalState))
    this.setState({ productsTotalState: newProductsTotalState, discountState: discount, applyPromoCodeState: orderPromoCodeValue })
    this.props.dispatch(applyPromoCode({ code: orderPromoCodeValue, discount: discount }))
  }


  calculateDiscount() {
    const { dispatch } = this.props;

    const { orderPromoCodeState, orderPromoCodeValue, productsTotalState } = this.state;
    const { appReducer } = store.getState()
    const { selectedLocation } = appReducer
    const selectedLocationId = 5

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var url = config.BASE_URI + "/api/v1/validate-promo/";
    var raw = JSON.stringify({ "payload": { "body": { "orderLocation": selectedLocationId, "orderPromoCode": orderPromoCodeValue, "totalAmount": productsTotalState } } });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(url, requestOptions)
      .then(response => response.json())
      .then(promoCodeResponse => {
        console.log("version_1.0.12 calculateDiscount() => ", promoCodeResponse)
        if (promoCodeResponse.success == true) {
          var promoCode = promoCodeResponse.promocode
          if (promoCode.promo_type == "Discount Rate") {
            var discount = (promoCode.discount_rate / 100) * productsTotalState
            var newProductsTotalState = Number(productsTotalState)
            this.setState({ productsTotalState: newProductsTotalState, discountState: discount, applyPromoCodeState: orderPromoCodeValue })
            this.props.dispatch(updateCartTotal(newProductsTotalState))
            this.props.dispatch(applyPromoCode({ code: orderPromoCodeValue, discount: discount }))
          }
          else if (promoCode.promo_type == "Discount Dollars") {
            var discount = promoCode.discount_dollars
            var newProductsTotalState = Number(productsTotalState)
            //console.log("Calculate Discount newAmount", newProductsTotalState)
            this.setState({ productsTotalState: newProductsTotalState, discountState: discount, applyPromoCodeState: orderPromoCodeValue })
            this.props.dispatch(updateCartTotal(newProductsTotalState))
            this.props.dispatch(applyPromoCode({ code: orderPromoCodeValue, discount: discount }))
          }
          else if (promoCode.promo_type == "Free Item") {
            this.addFreeItem(promoCode)
            var discount = promoCode.free_item_price
            var newProductsTotalState = Number(productsTotalState) + Number(discount)
            //console.log("Calculate Discount newAmount", newProductsTotalState)
            this.setState({ productsTotalState: newProductsTotalState, discountState: discount, applyPromoCodeState: orderPromoCodeValue })

            this.props.dispatch(updateCartTotal(newProductsTotalState))
            this.props.dispatch(applyPromoCode({ code: orderPromoCodeValue, discount: discount }))
          }
          else if (promoCode.promo_type == "Free Delivery") {
            var discount = this.state.deliveryChargesState
            var newProductsTotalState = Number(productsTotalState)
            //console.log("Calculate Discount newAmount", newProductsTotalState)
            this.setState({ productsTotalState: newProductsTotalState, discountState: discount, applyPromoCodeState: orderPromoCodeValue })
            this.props.dispatch(updateCartTotal(newProductsTotalState))
            this.props.dispatch(applyPromoCode({ code: orderPromoCodeValue, discount: discount }))
          }
        }
        else {

          this.setState({ promoCodeError: promoCodeResponse.error })
        }
      })
      .catch(error => console.log('error', error));

  }

  handleChange = orderLocationState => {
    const { dispatch } = this.props;
    this.setState({ orderLocationState: orderLocationState }, () => {
      dispatch(updateOrderLocation(orderLocationState))
    });
  };

  handleSelect = orderLocationState => {
    const { dispatch } = this.props;
    geocodeByAddress(orderLocationState)
      .then(results => {
        //console.log(results[0].formatted_address)
        this.setState({ orderLocationState: results[0].formatted_address }, () => {
          dispatch(updateOrderLocation(results[0].formatted_address))
          this.calculateDeliveryPrices()
        });
        getLatLng(results[0])
      })
      .then(latLng => console.log('Success', latLng))
      .catch(error => console.error('Error', error));
  };

  calculateDeliveryPrices() {
    const { appReducer } = store.getState()
    let delivery = this.state.serverDeliveryChargesArray
    const { selectedLocation, selectedLocationId, orderLocation } = appReducer

    var url = config.BASE_URI + "/api/v1/get-delivery-charge/";
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify(
      {
        "payload":
        {
          "body":
          {
            "kitchaco": selectedLocationId,
            "deliveryLocation": orderLocation
          }
        }
      });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(url, requestOptions)
      .then(response => response.json())
      .then(distanceResponse => {
        console.log("version_1.0.12 calculateDelivery() => ", distanceResponse)
        if (distanceResponse.success == true) {
          this.setState(
            {
              deliveryChargesState: distanceResponse.delivery_charge,
              deliveryLocationDistance: distanceResponse.distance,
              deliveryAllowed: true,
              deliveryAllowedError: '',
            },
            () => {
              //console.log(this.state.deliveryChargesState)
              this.props.dispatch(updateDeliveryCharges(this.state.deliveryChargesState))
            }
          );
        }
        else {
          this.setState({
            deliveryLocationDistance: null,
            deliveryAllowed: false,
            deliveryAllowedError: distanceResponse.error,
            deliveryChargesState: 0.0,
          }, () => {
            this.props.dispatch(updateDeliveryCharges(this.state.deliveryChargesState))
          });
        }
      })
      .catch(error => console.log('error', error));
  }

  removeProduct = key => {
    const { productsState } = this.state
    const { appReducer } = store.getState()
    const { products } = appReducer
    const { dispatch } = this.props
    var jsonObj = productsState
    //console.log("Remove ", this.state.productsState)
    delete jsonObj[key];
    this.setState({ productsState: jsonObj }, () => {
      console.log("Removed ", this.state.productsState)
      dispatch(updateCartProducts(this.state.productsState))
      dispatch(updateCartTotal(this.calculateCartTotal(this.state.productsState)))

    })
  }

  addFreeItem = (product) => {
    var obj = {
      "name": product.free_item_name,
      "id": product.free_item_id,
      "path": "",
      "prices": { "AUD": product.free_item_price },
      "currency": "AUD",
      "imageSrc": '',
      "properties": { "Combo": [[]], "Subitem": [null], "SpecialRequest": [""] },
      "propertiesToShowInCart": ["Combo", "Subitem", "SpecialRequest"],
      "quantity": 1,
      "additionalCost": []
    }

    var key = "freeitem"
    this.setState({ productsState: { ...this.state.productsState, [key]: obj } }, () => {
      console.log("Free Item ", this.state.productsState)
    })
  }



  getKitchacoLocationData = () => {
    var requestOptions = {
      method: 'get',
      redirect: 'follow'
    };

    fetch(config.BASE_URI + apiPaths.explore.location, requestOptions)
      .then(response => response.json())
      .then(result => {
        this.setState({ kitchacoLocations: result })
        console.log("olaola", result)
      })
      .catch(error => console.log('error', error));
  }


  validateOrderForLaterTime = (selectedTime) => {
    console.log("order for later ", selectedTime)
    var selectedTimeStamp = moment(selectedTime, "YYYY-MM-DDTHH:mm")

    var nowTimeStamp = moment(new Date())
    var laterTimeStamp = moment(nowTimeStamp, "YYYY-MM-DDTHH:mm").add(15, 'minutes')
    var diff = moment(selectedTimeStamp).diff(
      moment(nowTimeStamp),
      "minutes"
    );


    if (diff < 15) {
      console.log("order for later diff ", diff)
      return false
    }
    else {
      return true
    }
  }






  render() {
    const {
      appReducer
    } = store.getState()
    const selectedLocationId = 1
    const {
      
      selectedLocation,
      products,
      productsTotal,
      userId,
      location
    } = appReducer
    const {
      dispatch
    } = this.props
    const {
      loaded,
      orderTypeState,
      orderLaterState,
      orderDateState,
      deliveryChargesState,
      applyPromoCodeState,
      orderPromoCodeValue,
      discountState,
      isOpenLoginModal,
      showRequiredToast,
      showToastMessage,
      isLogout,
      kitchacoLocations,
      futureDateError,
    } = this.state

    console.log(kitchacoLocations)
    var selectedLocationObj = kitchacoLocations.filter(obj => {
      return obj.id == 1
      
    })




    return (
      loaded &&
      <>
        {selectedLocationId && selectedLocation && selectedLocationId != '' && selectedLocation != '' ?
          <NewNavBar kitchacoLocation={selectedLocation} kitchacoLocationId={1} productsState={products} productsTotalState={productsTotal} discountState={discountState} userId={userId} dispatch={dispatch} isOpenLoginModalProps={isOpenLoginModal} isLogout={isLogout} orderTypeState={this.state.orderTypeState} deliveryChargesState={this.state.deliveryChargesState} deliveryAllowedError={this.state.deliveryAllowedError} togglePaymentModal={() => { this.togglePaymentModal() }} calculateTotal={() => { this.calculateTotal() }} ></NewNavBar>
          :
          null}
        <Container>
          <Row>
            <div
              aria-live="polite"
              aria-atomic="true"
              style={{
                position: 'absolute',
                minHeight: '100px',
                zIndex: 100,
              }}
            >
              <Toast
                style={{
                  position: 'fixed',
                  top: 0,
                  right: 0,
                }}
                onClose={() => this.setState({ showRequiredToast: false })} show={showRequiredToast} delay={3000} autohide
              >
                <Toast.Header>
                  <img src="holder.js/20x20?text=%20" className="rounded mr-2" alt="" />
                  <strong className="mr-auto">Error!</strong>
                  <small></small>
                </Toast.Header>
                <Toast.Body>{showToastMessage}</Toast.Body>
              </Toast>
            </div>
            <Col lg={8} className="checkout-screen-cart">
              <CartComponent
                hideHeader={true}
                products={
                  products

                }
                getLocalization={getDefaultLocalization('cart', 'en', localization)}
                currency="AUD"

                isCartEmpty={false}
                onRemoveProduct={(key) => {
                  var removePromoCodeMessage = false
                  if (applyPromoCodeState != "" && applyPromoCodeState != null) {
                    removePromoCodeMessage = true
                  }
                  this.setState({ showRemoveItemConfirmation: true, removeItemKey: key, removePromoCodeMessage: true })
                }}


              />
              <Row style={{ marginTop: 10 }}>
                <Col xs={6}>
                  <h5 style={{ textAlign: 'left' }}>Sub Total:</h5>
                </Col>
                <Col xs={6}>
                  <h5 style={{ textAlign: 'left' }}>${Number(this.calculateSubTotal()).toFixed(2)}</h5>
                </Col>
              </Row>
              {applyPromoCodeState != '' && applyPromoCodeState != null
                ?
                <Row>
                  <Col xs={6}>
                    <h5 style={{ textAlign: 'left' }}>Discount:</h5><p>{applyPromoCodeState}</p>
                  </Col>
                  <Col xs={6}>
                    <h5 style={{ textAlign: 'left' }}>- ${Number(discountState).toFixed(2)}</h5>
                  </Col>

                </Row>
                : null}

              {orderTypeState === 'delivery' ?
                <Row>
                  <Col xs={6}>
                    <h5 style={{ textAlign: 'left' }}>Delivery Charges:</h5>
                  </Col>
                  <Col xs={6}>
                    <h5 style={{ textAlign: 'left' }}>${deliveryChargesState}</h5>
                  </Col>
                </Row>
                : null}

              <Row style={{ marginTop: 10 }}>
                <Col xs={6}>
                  <h5 style={{ textAlign: 'left' }}>Total:</h5>
                </Col>
                <Col xs={6}>
                  <h5 style={{ textAlign: 'left' }}>${Number(this.calculateTotal()).toFixed(2)}</h5>
                </Col>
              </Row>
              {userId != "" && userId != null ?

                <Row className="desktop-only">
                  <Col xs={12}>
                    <Button onClick={() => {
                      if ((orderTypeState == 'delivery' && deliveryChargesState != 0.0) || orderTypeState == 'pickup') {
                        this.togglePaymentModal()
                      }
                      else {
                        if (this.state.deliveryAllowedError) {
                          alert("Error! " + this.state.deliveryAllowedError)
                        }
                        else {
                          alert("Error! Please check all the fields and try again!")
                        }
                      }

                    }
                    } block className="button-enabled-pink-style" style={((orderTypeState == 'delivery' && deliveryChargesState != 0.0) || orderTypeState == 'pickup') ? { marginTop: 20, backgroundColor: '#f05b4b' } : { marginTop: 20, borderColor: '#999999', backgroundColor: '#cccccc', color: '#666666' }}>
                      Pay Now ${Number(this.calculateTotal()).toFixed(2)}
                    </Button>
                  </Col>
                </Row>
                : null}
            </Col>

            <Col lg={4}>
              <div className="desktop-only"><Card.Img src={require('../../assets/img/default_cover.jpg')} /></div>
              <Row style={{ marginTop: 10 }}>
                <Col xs={6}>
                  <h5 style={{ textAlign: 'left' }}>Location:</h5>
                </Col>
                <Col xs={6}>
                  <h5 style={{ textAlign: 'left' }}>{selectedLocation}</h5>
                </Col>
              </Row>
              {orderTypeState != '' && ((orderTypeState == 'delivery' && deliveryChargesState != 0.0) || orderTypeState == 'pickup') ?
                <Row style={{ marginTop: 10 }}>
                  <Col xs={12}>
                    <Row>
                      <Col xs={12}>
                        <h4>Enter Promo Code</h4>
                        <p>(optional)</p>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12}>
                        <Row>
                          <Col xs={6}>

                            <InputGroup className="mb-3">
                              {applyPromoCodeState == "" || applyPromoCodeState == null ?
                                <FormControl
                                  placeholder="Promo Code"
                                  aria-label="Username"
                                  aria-describedby="basic-addon1"
                                  value={orderPromoCodeValue}
                                  onChange={(event) => { this.setState({ orderPromoCodeValue: event.target.value }) }}
                                />
                                :
                                <FormControl
                                  placeholder="Promo Code"
                                  aria-label="Username"
                                  aria-describedby="basic-addon1"
                                  value={applyPromoCodeState}
                                  readOnly
                                  onChange={(event) => { console.log("") }}
                                />


                              }
                            </InputGroup>
                            <p>{this.state.promoCodeError}</p>
                            <p style={{ cursor: 'pointer' }} onClick={() => this.changePromo()}>Click here to edit</p>
                            {/* <TextField
                                        style={{ border: "1px solid " + theme.colors.borderColor, borderRadius: 20 }}
                                        id="promo"
                                        type="text"
                                        defaultValue={orderPromoCodeValue.toString()}
                                        onChange={(event) => { //console.log(event.target.value) }}
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                      /> */}
                          </Col>
                          <Col xs={6}>
                            <CustomButton
                              backgroundColor={orderPromoCodeValue != "" ? theme.colors.primaryColor : theme.colors.backgroundColor}
                              textColor={orderPromoCodeValue != "" ? theme.colors.textColorLight : theme.colors.textColorDark}
                              borderRadius={20}
                              isProfilePic={false}
                              buttonText={applyPromoCodeState == "" || applyPromoCodeState == null ? 'Apply' : 'Applied'}
                              buttonWidth={'100%'}
                              handleButtonClick={applyPromoCodeState == "" || applyPromoCodeState == null ? (orderPromoCodeValue != "" ? () => { this.calculateDiscount() } : null) : null}
                            ></CustomButton>


                          </Col>
                        </Row>


                      </Col>
                    </Row>
                  </Col>
                </Row>
                : null}
              {userId != "" && userId != null ?
                <>

                  <Row style={{ marginTop: 10 }} id="order-type">
                    <Col xs={12}>
                      <h5 style={{ textAlign: 'left' }}>Order Type:</h5><p>Please select your order type</p>
                    </Col>
                    <Col xs={12}>
                      <Row>
                        <Col xs={6}>
                          <CustomButton
                            backgroundColor={orderTypeState === 'delivery' ? theme.colors.primaryColor : theme.colors.backgroundColor}
                            textColor={orderTypeState === 'delivery' ? theme.colors.textColorLight : theme.colors.textColorDark}
                            borderRadius={20}
                            isProfilePic={false}
                            buttonText={'Delivery'}
                            buttonWidth={'100%'}
                            handleButtonClick={() => { this.changePromo(); this.setState({ orderTypeState: 'delivery', orderLocationState: '' }, () => { dispatch(updateOrderType(this.state.orderTypeState)); dispatch(updateOrderLocation(this.state.orderLocationState)) }) }}
                          ></CustomButton>
                        </Col>
                        <Col xs={6}>
                          <CustomButton
                            backgroundColor={orderTypeState === 'pickup' ? theme.colors.primaryColor : theme.colors.backgroundColor}
                            textColor={orderTypeState === 'pickup' ? theme.colors.textColorLight : theme.colors.textColorDark}
                            borderRadius={20}
                            isProfilePic={false}
                            buttonText={'Pickup'}
                            buttonWidth={'100%'}
                            handleButtonClick={() => { this.changePromo(); this.setState({ orderTypeState: 'pickup', deliveryChargesState: 0.0, orderLocationState: getFromLocalStorage("selectedLocation") }, () => { dispatch(updateOrderType(this.state.orderTypeState)); dispatch(updateDeliveryCharges(this.state.deliveryChargesState)); dispatch(updateOrderLocation(getFromLocalStorage("selectedLocation"))) }) }}
                          ></CustomButton>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  {orderTypeState === 'delivery' ?
                    <Row style={{ marginTop: 50 }}>
                      <Col xs={12}>
                        <Row>
                          <Col xs={12}>
                            <h5>Enter your delivery location:</h5>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={12}>
                            <PlacesAutocomplete
                              value={this.state.orderLocationState}
                              onChange={this.handleChange}
                              onSelect={this.handleSelect}
                              searchOptions={{
                                componentRestrictions: { country: 'au' }
                              }}
                            >
                              {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                <div>
                                  <input
                                    {...getInputProps({
                                      placeholder: 'Enter Delivery Location (Required)',
                                      className: 'location-search-input',
                                    })}
                                  />
                                  <div className="autocomplete-dropdown-container">
                                    {loading && <div>Loading...</div>}
                                    {suggestions.map(suggestion => {
                                      const className = suggestion.active
                                        ? 'suggestion-item--active'
                                        : 'suggestion-item';
                                      // inline style for demonstration purpose
                                      const style = suggestion.active
                                        ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                        : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                      return (
                                        <div
                                          {...getSuggestionItemProps(suggestion, {
                                            className,
                                            style,
                                          })}
                                        >
                                          <span>{suggestion.description}</span>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              )}
                            </PlacesAutocomplete>
                            {this.state.deliveryChargesState != 0.0 ?
                              <p>A delivery charge of ${this.state.deliveryChargesState} will apply.</p>
                              :

                              <p style={{ color: 'red', marginTop: 10 }}>{this.state.deliveryAllowedError}</p>
                            }

                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    : null}

                  <Row style={{ marginTop: 10, marginBottom: orderLaterState ? 0 : 150 }} id="order-time">
                    <Col xs={12}>
                      <h5 style={{ textAlign: 'left' }}>Order Time:</h5><p>Please select your order time</p>
                    </Col>
                    <Col xs={12}>
                      <Row>
                        <Col xs={6}>
                          <CustomButton
                            backgroundColor={orderLaterState === false ? theme.colors.primaryColor : theme.colors.backgroundColor}
                            textColor={orderLaterState === false ? theme.colors.textColorLight : theme.colors.textColorDark}
                            borderRadius={20}
                            isProfilePic={false}
                            buttonText={'Order Now'}
                            buttonWidth={'100%'}
                            handleButtonClick={() => { this.setState({ orderLaterState: false, orderDateState: moment(new Date()).format('YYYY-MM-DDTHH:mm') }, () => { dispatch(updateOrderLater(this.state.orderLaterState.toString())); dispatch(updateOrderDate(this.state.orderDateState)) }) }}
                          ></CustomButton>
                        </Col>
                        <Col xs={6}>
                          <CustomButton
                            backgroundColor={orderLaterState === true ? theme.colors.primaryColor : theme.colors.backgroundColor}
                            textColor={orderLaterState === true ? theme.colors.textColorLight : theme.colors.textColorDark}
                            borderRadius={20}
                            isProfilePic={false}
                            buttonText={'Order for later'}
                            buttonWidth={'100%'}
                            handleButtonClick={() => { this.setState({ orderLaterState: true }, () => { dispatch(updateOrderLater(this.state.orderLaterState.toString())) }) }}
                          ></CustomButton>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  {orderLaterState === true ?
                    <Row id="order-later-time" style={{ marginTop: 50, marginBottom: isRestaurantOpen(moment, kitchacoLocations, selectedLocationObj, 'schedule_order', orderDateState, orderDateState) ? 150 : 0 }}>
                      <Col xs={8}>
                        <Row>
                          <Col xs={12}>
                            <h4>Enter Order Time</h4>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={12}>

                            <form noValidate>
                              <TextField
                                id="datetime-local"
                                type="datetime-local"
                                defaultValue={orderDateState.toString()}
                                onChange={(event) => {

                                  var isTimeValid = this.validateOrderForLaterTime(event.target.value)
                                  console.log("order for later ", isTimeValid)
                                  if (isTimeValid == false) {
                                    this.setState({ showRequiredToast: true, showToastMessage: 'Please select a time atleast 15 min in the future' })
                                    // Please select a time atleast 15 min in the future
                                    this.setState({ orderDateState: '', futureDateError: true }, () => {
                                      dispatch(updateOrderDate(this.state.orderDateState))
                                    })
                                  }
                                  else {
                                    this.setState({ orderDateState: event.target.value, futureDateError: false }, () => {
                                      // // console.log("Hello Order Date", this.state.orderDateState)
                                      dispatch(updateOrderDate(this.state.orderDateState))
                                    })
                                  }
                                }}

                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </form>

                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    : null}

                  {!isRestaurantOpen(moment, kitchacoLocations, selectedLocationObj, 'schedule_order', orderDateState, orderDateState) && !futureDateError ?
                    <Row style={{ marginTop: 10, marginBottom: 150 }} id="opening-hours">
                      <Col xs={8}>
                        <Row>
                          <Col xs={12}>
                            <h4>Restaurant closed</h4>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={12}>
                            <p>{"Restaurant is closed on " + moment(orderDateState).format("DD MMM YYYY") + " at " + moment(orderDateState).format("hh:mm A") + "."}
                              <br></br>Please select <span style={{ cursor: 'pointer' }} onClick={() => { this.setState({ orderLaterState: true }, () => { dispatch(updateOrderLater(this.state.orderLaterState.toString())) }) }}><b>Order for later</b></span> above and choose a different date or time</p>

                          </Col>
                        </Row>
                        <Row>
                          <Col xs={12}>
                            <h4>Opening Hours:</h4>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={12}>
                            <p>Monday: {selectedLocationObj[0].monday_time != 0 ? selectedLocationObj[0].monday_time : 'Closed'}</p>
                            <p>
                              Tuesday: {selectedLocationObj[0].tuesday_time != 0 ? selectedLocationObj[0].tuesday_time : 'Closed'}
                            </p>
                            <p >
                              Wednesday: {selectedLocationObj[0].wednesday_time != 0 ? selectedLocationObj[0].wednesday_time : 'Closed'}
                            </p>
                            <p>
                              Thursday: {selectedLocationObj[0].thursday_time != 0 ? selectedLocationObj[0].thursday_time : 'Closed'}
                            </p>
                            <p>
                              Friday: {selectedLocationObj[0].friday_time != 0 ? selectedLocationObj[0].friday_time : 'Closed'}
                            </p>
                            <p>
                              Saturday: {selectedLocationObj[0].saturday_time != 0 ? selectedLocationObj[0].saturday_time : 'Closed'}
                            </p>
                            <p>
                              Sunday: {selectedLocationObj[0].sunday_time != 0 ? selectedLocationObj[0].sunday_time : 'Closed'}
                            </p>
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    : null}

                  {!isRestaurantOpen(moment, kitchacoLocations, selectedLocationObj, 'schedule_order', orderDateState, orderDateState) && futureDateError ?
                    <Row style={{ marginTop: 10, marginBottom: 150 }}>
                      <Col xs={12}>
                        <Row>
                          <Col xs={12}>
                            <h4>Error!</h4>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={12}>
                            <p>Please select a time atleast 15 minutes in the future!</p>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    : null}
                </>
                :
                <Row style={{ width: '100%', padding: 10, margin: '0 auto', backgroundColor: 'white', paddingBottom: 30 }}>
                  <Button
                    style={{
                      borderStyle: 'solid',
                      borderRadius: 40,
                      borderWidth: 1,
                      borderColor: "#f05b4b",
                      backgroundColor: '#f05b4b',
                      marginTop: 20
                    }}
                    onClick={() => {
                      this.setState({ isOpenLoginModal: true })


                    }
                    } block className="button-enabled-pink-style">
                    Sign In to complete your order
                  </Button>
                </Row>
              }
            </Col>
          </Row>
          {this.renderPaymentModal()}
          {this.renderRemoveItemConfirmation()}
          {this.renderPaymentConfirmation()}
        </Container>
      </>
    )
  }


  renderPaymentModal() {
    const {
      isPaymentModalOpen,
      productsTotalState,

      deliveryChargesState,
      orderTypeState,
      productsState,
      ticketState,
      orderDateState,
      orderLaterState,
      discountState,
      applyPromoCodeState,
      kitchacoLocations


    } = this.state

    const { dispatch, userId } = this.props

    const { appReducer } = store.getState()
    const { selectedLocationId } = 5
    var selectedLocationObj = kitchacoLocations.filter(obj => {
      return obj.id == 5
    })

    return (
      <Modal show={isPaymentModalOpen} onHide={this.togglePaymentModal.bind(this)}>
        <Modal.Header closeButton>
          <Modal.Title>Enter Payment Details Below</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Card style={{ height: 500, marginTop: 20, overflowY: 'scroll', border: 'none' }}>
            <Card.Body variant="bottom" style={{ padding: 5 }}>
              <Card.Title></Card.Title>
              <Row>
                <Col xs={12}>
                  <PaymentForm
                    paymentForm={window.Square}
                    user={userId}
                    productsTotal={productsTotalState}
                    deliveryCharges={deliveryChargesState}
                    orderType={orderTypeState}
                    dispatch={dispatch}
                    productsState={productsState}
                    orderDateState={orderDateState}
                    orderLaterState={orderLaterState}
                    discountState={discountState}
                    applyPromoCode={applyPromoCodeState}
                    squareLocationId={selectedLocationObj[0].square_location_id}
                    openPaymentConfirmation={() => { this.openPaymentConfirmation() }}

                  />

                </Col>
              </Row>


            </Card.Body>
          </Card>

        </Modal.Body>

        <Modal.Footer>

        </Modal.Footer>
      </Modal>
    )
  }

  renderRemoveItemConfirmation() {
    const { showRemoveItemConfirmation, removeItemKey, removePromoCodeMessage } = this.state

    return (
      <Modal show={showRemoveItemConfirmation} onHide={() => { this.setState({ showRemoveItemConfirmation: false }) }}>
        <Modal.Header >
          <Modal.Title>Are you sure?</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ textAlign: 'center', width: '90%', margin: 'auto', color: 'black' }}>
          {removePromoCodeMessage ?
            'This will also remove any promo code that you have applied. You can apply the promo code again.'
            :
            'Are you sure you want to delete this item from your cart?'
          }

          <Row style={{ marginTop: 10 }}>
            <Col xs={6}>
              <CustomButton
                backgroundColor={'#f05b4b1a'}
                textColor={theme.colors.primaryColor}
                borderRadius={20}
                isProfilePic={false}
                buttonText={'Cancel'}
                margin={'auto'}
                buttonWidth={'90%'}
                handleButtonClick={() => {
                  this.setState({ showRemoveItemConfirmation: false })

                }}
              ></CustomButton>
            </Col>
            <Col>
              <CustomButton
                backgroundColor={theme.colors.primaryColor}
                textColor={theme.colors.textColorLight}
                borderRadius={20}
                isProfilePic={false}
                buttonText={'Yes'}
                margin={'auto'}
                buttonWidth={'90%'}
                handleButtonClick={() => {
                  this.setState({ showRemoveItemConfirmation: false }, () => {
                    if (removePromoCodeMessage) {
                      this.changePromo()
                    }
                    this.removeProduct(removeItemKey)
                  })

                }}
              ></CustomButton>
            </Col>
          </Row>




        </Modal.Body>

      </Modal>
    )
  }

  renderPaymentConfirmation() {
    const {
      isOpenPaymentConfirmation
    } = this.state
    return (
      <Modal show={isOpenPaymentConfirmation} onHide={() => { this.setState({ isOpenPaymentConfirmation: false }); window.location.reload() }}>
        <Modal.Header closeButton>
          <Modal.Title>Payment Successfull</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Card style={{ height: 500, marginTop: 20, overflowY: 'scroll', border: 'none' }}>
            <Card.Body variant="bottom" style={{ padding: 5 }}>
              <Card.Title></Card.Title>
              <Row>
                <Col xs={12} style={{ textAlign: 'center' }}>
                  <img src={require('../../assets/img/check.png')} className="rounded mr-2" alt="successfull" />
                  <h3>Your payment was successfull and your order was received! You can check your order status by going to the "Order History Page"</h3>
                  <Button onClick={() => { window.location.href = "/v2/order-history" }} block className="button-enabled-pink-style" style={{ marginTop: 20, backgroundColor: '#f05b4b' }}>
                    Check Order Status
                  </Button>

                </Col>
              </Row>


            </Card.Body>
          </Card>

        </Modal.Body>

        <Modal.Footer>

        </Modal.Footer>
      </Modal>
    )
  }


}

function mapStateToProps(state) {
  return {
    selectedTab: state.appReducer.selectedTab,
    vendorData: state.appReducer.vendorData,
    filters: state.appReducer.filters,
    selectedCategory: state.appReducer.selectedCategory,
    loggedIn: state.appReducer.loggedIn,
    smsSent: state.appReducer.smsSent,
    loginModalToggle: state.appReducer.loginModalToggle,
    phone: state.appReducer.phone,
    userId: state.appReducer.userId,
    token: state.appReducer.token,
    username: state.appReducer.username,
    email: state.appReducer.email,
    userAddress: state.appReducer.userAddress,
    userImg: state.appReducer.userImg,
    userLocation: state.appReducer.userLocation,
    currentLatitude: state.appReducer.currentLatitude,
    currentLongitude: state.appReducer.currentLongitude,
    selectedVendorData: state.appReducer.selectedVendorData,
    paymentStatus: state.appReducer.paymentStatus
  }
}

export default connect(mapStateToProps)(CheckoutResponsiveScreen);